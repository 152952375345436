import * as React from "react";
import OpenSea from "../images/newicons/SOCIAL ICON SVGS-24.svg";
import Discord from "../images/newicons/SOCIAL ICON SVGS-22.svg";
import Twitter from "../images/newicons/SOCIAL ICON SVGS-21.svg";
import Insta from "../images/newicons/SOCIAL ICON SVGS-20.svg";
import mIcon from "../images/newicons/SOCIAL ICON SVGS-23.svg";

import LoaderIcon from "../images/2.0/loader-icon.png";
import { Link } from "gatsby";

const Icons = (props) => {
  const [showToggle, setShowToggle] = React.useState(false);

  return (
    <div
      className="icons"
      style={{ display: props.footerVisible ? "none" : "block" }}
    >
      {props.page === "home" && (
        <>
          <a
            href="#"
            className="sidenav-toggle"
            onClick={(e) => {
              e.preventDefault();
              setShowToggle(!showToggle);
            }}
          >
            <span></span>
          </a>
          <div
            className={showToggle ? "sidenav-container show" : "sidenav-container"}
          >
            <nav>
              <ul className="sidenav">
                <li className="has-sidenav-child">
                  <a href="#">NFT COLLECTION</a>
                  <ul className="sidenav-child">
                    <li>
                      <a href="/">DROP 002</a>
                    </li>
                    <li>
                      <a href="/drop-001">DROP 001</a>
                    </li>
                    <li>
                      <Link to="/genesis">GENESIS</Link>
                    </li>

                  </ul>
                </li>
                <li className="has-sidenav-child">
                  <a href="#">METAVERSE</a>
                  <ul className="sidenav-child">
                    <li>
                      <a href="/metaverse">CULTR WORLD</a>
                    </li>
                  </ul>
                </li>
                <li className="has-sidenav-child">
                  <a href="/about">ABOUT</a>
                </li>

              </ul>
            </nav>
          </div>
        </>
      )}
      <div className="icons_container">
        {false&&<a className="header-login" href="/account">
          <nobr>LOG IN</nobr>
        </a>}
        {props.page === "drop001" && (
          <a href="https://opensea.io/collection/cultandrain-drop-001">
            <img className="opensea" src={OpenSea} />
          </a>
        )}
        {props.page === "genesis" && (
          <a href="https://opensea.io/collection/cultandrain-genesis">
            <img className="opensea" src={OpenSea} />
          </a>
        )}
        <a href="http://discord.gg/cultandrain">
          <img src={Discord} />
        </a>
        <a href="https://twitter.com/cultandrain">
          <img src={Twitter} />
        </a>
        <a href="https://www.instagram.com/cultandrain/">
          <img src={Insta} />
        </a>
        <a
          href="https://cultandrain.medium.com/"
          className="icon"
          style={{ marginRight: "-6px" }}
        >
          <img src={mIcon} />
        </a>
        {/*!props.mintVisible && (
          <div>
            <a className="icons-mint" href="/claim-drop-001">
              <nobr>Claim Now</nobr>
            </a>
          </div>
        )*/}
        {/* {props.page !== "genesis" && (
          <div style={{ textAlign: "center" }}>
            <a className="icons-mint" href="/account">
              <nobr>My Account</nobr>
            </a>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Icons;
