import * as React from "react";
import OpenSea from "../images/newicons/SOCIAL ICON SVGS-24.svg";
import Discord from "../images/newicons/SOCIAL ICON SVGS-22.svg";
import Twitter from "../images/newicons/SOCIAL ICON SVGS-21.svg";
import Insta from "../images/newicons/SOCIAL ICON SVGS-20.svg";
import Medium from "../images/2.0/medium.svg";
import Globe from "../images/2.0/globe.png";
import mIcon from "../images/newicons/SOCIAL ICON SVGS-23.svg";
const isBrowser = typeof window !== "undefined";

const Footer = (props) => {
  const footerRef = React.useRef(null);

  let observer = null;

  React.useEffect(() => {
    if (footerRef.current) {
      observer = new IntersectionObserver(([entry]) =>
        props.setFooterVisible(entry.isIntersecting)
      );
      observer.observe(footerRef.current);
    }
    return () => {};
  });

  return (
    <div ref={footerRef} className="footer">
      <div className="header">
        <div className="headera">
          <h2>
            <nobr>GET IN TOUCH</nobr>
          </h2>
        </div>
        <div className="headerb">
          <div className="sayhi">
            <h5>SAY</h5>
            <p>
              <a href="mailto:hi@cultandrain.com" className="email">
                hi@cultandrain.com
              </a>
            </p>
          </div>
          <div className="icons-footer">
            {props.page == "drop001" && (
              <a href="https://opensea.io/collection/cultandrain-drop-001">
                <img className="opensea" src={OpenSea} />
              </a>
            )}
            {props.page != "drop001" && (
              <a href="https://opensea.io/collection/cultandrain-genesis">
                <img className="opensea" src={OpenSea} />
              </a>
            )}
            <a href="https://cultandrain.medium.com/" className="icon">
              <img src={mIcon} />
            </a>
            <a href="https://www.instagram.com/cultandrain/" className="icon">
              <img src={Insta} />
            </a>
            <a href="https://twitter.com/cultandrain" className="icon">
              <img src={Twitter} />
            </a>
            <a href="http://discord.gg/cultandrain" className="icon">
              <img src={Discord} />
            </a>
          </div>
        </div>
      </div>
      <div className="middle">
        <img src={Globe} alt="Globe" />
      </div>
      <div className="bottom">
        <div>
          <p>
            @2021 CULT & RAIN Inc, NYC. For the sake of interrupting luxury
            fashion.
          </p>
          <p>
            CULT & RAIN is a registered trademark of CULT & RAIN Inc. All Rights
            Reserved. All contents of this documents, unless otherwise credited,
            are copyright 2021 CULT & RAIN Inc.
          </p>
        </div>
        <div>
          <p>
            <a href="/privacy">
              <nobr>Privacy Policy</nobr>
            </a>
          </p>
          <p>
            <a href="/terms">
              <nobr>Terms & Conditions</nobr>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
