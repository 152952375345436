import * as React from "react";
import Plx from "react-plx";

const isBrowser = typeof window !== "undefined";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

function ValidateEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
}

// markup
const Join = (props) => {
  const joinRef = React.useRef(null);
  const formRef = React.useRef(null);
  const emailRef = React.useRef(null);

  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (formRef.current)
      formRef.current.addEventListener("submit", handleSubmit);
    props.total(joinRef.current ? joinRef.current.clientHeight : 0);
    return () => {
      if (formRef.current)
        formRef.current.removeEventListener("submit", handleSubmit);
    };
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(false);
    if (!ValidateEmail(emailRef.current.value)) {
      setError(true);
      return false;
    }
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        email: emailRef.current.value,
      }),
    })
      .then(() => setHasSubmitted(true))
      .catch((error) => alert(error));
  };

  return (
    <div ref={joinRef} className="join">
      {/* <div className="black" /> */}
      <h2>
        JOIN OUR
        <br />
        REVOLUTION
      </h2>
      <p>
        TOGETHER WE WILL BUILD A NEW <br className="joinbreak" />
        FASHION WEB3 UNIVERSE
      </p>

      {!hasSubmitted && (
        <form ref={formRef} name="Join" method="POST" data-netlify="true">
          <input
            ref={emailRef}
            placeholder="YOUR EMAIL"
            type="email"
            name="email"
          />
          <input type="submit" value="SUBMIT" />
          {error && <p style={{ color: "red" }}>Please enter a valid email.</p>}
        </form>
      )}
      {hasSubmitted && <p style={{ marginTop: "5vh" }}>THANK YOU!</p>}
    </div>
  );
};

export default Join;
